import { liveQuery } from "dexie";
import { For, Show, createEffect, createReaction, createSignal, from, onCleanup } from "solid-js";
import type { JSX } from "solid-js";

// @ts-ignore
import ircf from "../lib/irc-formatting";
import ColorHash from "../vendor/color-hash";

import type { AccountRecordKey, NetworkRecord } from "./db";
import { RoomsDB, type MessageRecord, type RoomRecordKey } from "./db/roomsDB";
import { useConnectionManager } from "./connection/context";
import { BiRegularCoffee, BiRegularMemoryCard } from "solid-icons/bi";

type MessagesProps = {
  accountId: AccountRecordKey,
  networkId: NetworkRecord["networkId"],
  roomName: RoomRecordKey,
}

export default function Messages({ accountId, networkId, roomName }: MessagesProps) {
  let $messages: HTMLElement|undefined;

  let $startMarker: HTMLElement|undefined = undefined;
  let startMarkerVisible = false;

  let $endMarker: HTMLElement|undefined = undefined;
  let endMarkerVisible = false;

  const [ ready, setReady ] = createSignal(false);

  const messagesDB = new RoomsDB(networkId ? [accountId,networkId] : [accountId]);
  
  const [ groupedMsgs, setGroupedMsgs ] = createSignal<MessageRecord[][]>();
  const msgs = from(liveQuery(() => messagesDB.msgs.where("roomName").equals(roomName).toArray()));

  createEffect(() => {
    if (!msgs() && !groupedMsgs())
      return;

    console.info("Loaded msgs!", msgs()?.length);

    const groups: MessageRecord[][] = [];

    for (const msg of (msgs()?.slice(msgs()!.length - 1000) || [])) {
      const lastGroup = groups.at(-1);
      if (lastGroup?.[0].prefix === msg.prefix) {
        lastGroup.push(msg);
      } else {
        groups.push([msg]);
      }
    }

    setGroupedMsgs(groups);
  });

  const manager = useConnectionManager()!;

  function loadAnterior() {
    const time = msgs()?.[0].time || new Date().toISOString();
    console.info('Load before', time);

    manager.getConnection(accountId, networkId)?.client.sendRaw(
      `CHATHISTORY BEFORE ${roomName} timestamp=${time} 20`
    );
  }

  const startObserver = new IntersectionObserver(entries => {
    const wasVisible = startMarkerVisible;
    startMarkerVisible = entries[0].isIntersecting
    if (startMarkerVisible && !wasVisible && msgs()?.length)
      loadAnterior();
  });

  createEffect(() => {
    startObserver.observe($startMarker!);
    onCleanup(() => startObserver.disconnect());
  });

  const scrollToEndMarkerWhen = createReaction(() => {
    requestIdleCallback(() => {
      $endMarker!.scrollIntoView({
        behavior: "instant",
        block: "start",
      });

      requestIdleCallback(() => {
        setReady(true);
      });
    }, { timeout: 500 });
  });

  scrollToEndMarkerWhen(() => msgs());

  const hasher = new ColorHash({
    lightness: 0.72,
    saturation: 1.05,
  });

  function colorHash(prefix: string, colorHasher: ColorHash = hasher) {
    return `rgb(${colorHasher.rgb(prefix).join(",")})`;
  }

  const room = from(liveQuery(() => messagesDB.rooms.get(roomName)));

  const setReadMarker = (msg: MessageRecord) => {
    return messagesDB.rooms.update(roomName, {
      lastReadTime: msg.time,
      lastReadId: msg.msgId,
      lastReadHash: msg.hash,
    });
  }

  const maybeSetReadMarker = () => {
    console.debug("maybeSetReadMarker", endMarkerVisible, msgs()?.length, document.hasFocus());
    if (endMarkerVisible && msgs()?.length && document.hasFocus()) {
      setReadMarker(msgs()!.at(-1)!);
    }
  }

  const endObserver = new IntersectionObserver(entries => {
    endMarkerVisible = entries[0].isIntersecting;
    maybeSetReadMarker();
  });

  createEffect(() => {
    maybeSetReadMarker();
  })

  createEffect(() => {
    endObserver.observe($endMarker!);
    onCleanup(() => endObserver.disconnect());
  });

  return (
    <soz-messages tabIndex={0} ref={$messages}
        class="animate__fadeIn animate__faster animate__animated"
        style={{ "animation-play-state": ready() ? "running" : "paused" }}
      >
        <hr ref={$startMarker} />
        <Show when={groupedMsgs()} fallback={<Loading />}>
          <Show when={groupedMsgs()?.length} fallback={<NoMessages />}>
            <ul>
              <For each={groupedMsgs()}>
                {group => (
                  <li>
                    <header>
                      <time title={new Date(group[0].time).toISOString()} dateTime={group[0].time}>{new Date(group[0].time).toLocaleTimeString()}</time>

                      <soz-message-author style={{ "color": colorHash(group[0].prefix) }} title={group[0].prefix}>
                        <a>
                          {/* <span style={{ "color": colorHash(group[0].prefix) }}>@</span> */}
                          {group[0].prefix.split("@", -2)[0].split("!")[0]}
                        </a>
                      </soz-message-author>
                    </header>
                    <ul>
                      <For each={group}>
                        {msg => (
                          <li>
                            <MessageContent msg={msg} />
                          </li>
                        )}
                      </For>
                    </ul>
                  </li>
                )}
              </For>
            </ul>
          </Show>
        </Show>
        <hr ref={$endMarker} />
      </soz-messages>
  )
}

type MessageContentProps = { msg: MessageRecord };

function safeFormat(text: string) {
  // our ircf.renderHtml escapes the content using the same algorith as Python's html.escape().
  return ircf.renderHtml(text);
}

function MessageContent({ msg }: MessageContentProps) {
  if (msg.type === "PRIVMSG" || msg.type === "NOTICE") {
    if (msg.content?.startsWith("\x01ACTION ")) {
      const text = msg.content.slice(8, msg.content?.lastIndexOf("\x01"));
      return <soz-message-text class="ACTION" innerHTML={safeFormat(text)} />
    } else {
      return <soz-slab>
        <soz-message-text innerHTML={safeFormat(msg.content!)} />
      </soz-slab>
    }
  } else if (msg.type === "JOIN") {
    return <soz-message-text class="JOIN">joined</soz-message-text>;
  } else if (msg.type === "PART") {
    return <soz-message-text class="PART">left</soz-message-text>;
  } else if (msg.type === "MODE") {
    return <soz-message-text class="MODE">mode {msg.content}</soz-message-text>;
  } else if (msg.type === "KICK") {
    return <soz-message-text class="MODE">kicks {msg.content}</soz-message-text>;
  } else {
    return <soz-message-text class={msg.type}>
      {msg.type} {msg.content}
    </soz-message-text>
  }
}

function Loading() {
  return (
    <article style={{ display: "grid", color: "gray", "gap": "1.2em", "place-content": "center", height: "100%" }}>
      <BiRegularMemoryCard fill="currentcolor" width="100%" display="block" style={{
        display: "block",
        "font-size": "3em",
        "place-self": "center",
      }} />
      <h2>loading msgs</h2>
      <h3 style={{ "font-weight": 400 }}>u must have a lot of them pls wait</h3>
    </article>
  )
}

function NoMessages() {
  return (
    <article style={{ display: "grid", color: "gray", "gap": "1.2em", "place-content": "center", height: "100%" }}>
      <BiRegularCoffee fill="currentcolor" width="100%" display="block" style={{
        display: "block",
        "font-size": "5em",
        "place-self": "center",
      }} />
      <h2>seems cozy here</h2>
      <h3 style={{ "font-weight": 400 }}>why not send a message?</h3>
    </article>
  )
}