import Dexie, { DexieOptions } from "dexie";
import { createContext, useContext } from "solid-js";
import { AccountRecordKey, NetworkRecord } from ".";

export type RoomRecord = {
  name: string;
  type: string;
  topic?: string;

  lastReadTime?: string;
  lastReadId?: string;

  lastMsgPrefix?: string;
  lastMsgTime?: string;
  lastMsgId?: string;

  lastMentionPrefix?: string;
  lastMentionTime?: string;
  lastMentionId?: string;
};

export type RoomRecordKey = RoomRecord["name"];

export type MessageRecord = {
  roomName: string;
  time: string;
  msgId: string;
  hash?: string; // used to disambiguate msgs with no time or msgid in primary key

  content?: string;
  prefix: string;
  tags?: Record<string, string>;
  type?: string;
};

export type MessageRecordKey = [
  MessageRecord["roomName"],
  MessageRecord["time"],
  MessageRecord["msgId"],
];

export type RoomsDBPath = [AccountRecordKey, NetworkRecord["networkId"]?];

export class RoomsDB extends Dexie {
  msgs!: Dexie.Table<MessageRecord, MessageRecordKey>;
  rooms!: Dexie.Table<RoomRecord, RoomRecordKey>;

  static defaultPrefix = "Soz";
  static nameSeparator = "/";

  static nameFor(path: RoomsDBPath) {
    return [RoomsDB.defaultPrefix, ...path].join(RoomsDB.nameSeparator)
  }

  constructor(path: RoomsDBPath, options?: DexieOptions) {
    const name = RoomsDB.nameFor(path);
    super(name, options);

    this.version(12).stores({
      rooms: "name, type, lastMsgTime",
      msgs: "[roomName+time+hash+msgId], time",
    });
  }
}

export const RoomsDBContext = createContext<RoomsDB>();

export function useRoomsDB() {
  return useContext(RoomsDBContext)!;
}
