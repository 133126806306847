import { Accessor, For, createEffect } from "solid-js";
import { AccountRecord, AccountRecordKey, NetworkRecord } from "./db";
import { useConnection, useConnectionManager } from "./connection/context";
import { BiRegularSend } from "solid-icons/bi";

type ConnectionRoomOptions = {
  accountId: AccountRecordKey,
  networkId?: NetworkRecord["networkId"],

  log: Accessor<string[]>;
};

export default function ConnectionRoom({ accountId, networkId, log }: ConnectionRoomOptions) {
  let $messages: HTMLElement|undefined;

  const manager = useConnectionManager();
  
  const sendMessage = (event: SubmitEvent) => {
    const target = event.currentTarget as HTMLFormElement;
    const input = target.querySelector("input")!;
    const text = input.value;

    const conn = manager?.getConnection(accountId, networkId);
    conn!.client.sendRaw(text);

    target.reset();
  }

  createEffect(() => {
    log?.();
    $messages?.scrollTo({
      behavior: "instant",
      top: 999999999,
    });
  });

  return (
    <soz-room role="tab">
      <header>
        {/* <h3>{network ? (network.nickname || network.name) : account.name}</h3> */}
      </header>

      <soz-messages tabIndex={0} ref={$messages} class="monospace">
        <ul>
          <For each={log?.() || []}>
            {raw => (
              <li>
                <soz-message-text>{raw}</soz-message-text>
              </li>
            )}
          </For>
        </ul>
      </soz-messages>
      
      <form action="javascript:" onsubmit={sendMessage}>
        <input type="text" spellcheck={false} placeholder="Try pressing Esc" />
        <button type="submit">
          <BiRegularSend />
        </button>
      </form>
    </soz-room>
  )
}