import { BiRegularCoffee, BiRegularMemoryCard, BiRegularSend } from "solid-icons/bi";
import { For, JSX, Show, createDeferred, createEffect, createReaction, createSignal, from, getOwner, onCleanup } from "solid-js";
import { MessageRecord, RoomsDB } from "./db/roomsDB";
import { AccountRecordKey, NetworkRecord } from "./db";
import { liveQuery } from "dexie";
import { useConnectionManager } from "./connection/context";
import { ChannelPart, PrivateMessage, Topic } from "../vendor/ircv3/Message/MessageTypes/Commands";

import ColorHash from "../vendor/color-hash";
import { isChannel } from "../vendor/ircv3";
import { useNavigate } from "@solidjs/router";

import Messages from "./Messages";

type RoomProps = {
  accountId: AccountRecordKey;
  networkId?: NetworkRecord["networkId"];
  roomName: string;

  appButton?: JSX.Element,
};

export default function Room({ accountId, networkId, roomName, appButton }: RoomProps) {
  roomName = decodeURIComponent(roomName);

  const messagesDB = new RoomsDB(networkId ? [accountId,networkId] : [accountId]);

  const room = from(liveQuery(() => messagesDB.rooms.get(roomName)));

  const manager = useConnectionManager()!;
  const navigate = useNavigate();

  const sendMessage = (event: SubmitEvent) => {
    const target = event.currentTarget as HTMLFormElement;
    const input = target.querySelector("input")!;
    const text = input.value;

    event.preventDefault();

    const conn = manager.getConnection(accountId, networkId);
    if (!conn?.client.isConnected)
      return;

    if (!text.trim())
      return target.reset();

    const cmdMatch = text.trimStart().match(/^\/([^\s]+) ?(.*)/);
    if (cmdMatch) {
      const [ _, cmd, tail ] = cmdMatch;
      if (cmd.match(/^[A-Z]+/)) {
        conn!.client.sendRaw(text.trimStart().slice(1));
      } else if (cmd === "me" || cmd === "action") {
        conn!.client.action(roomName, tail);
      } else if (cmd === "join") {
        conn!.client.join(tail.trim());
      } else if (cmd === "part") {
        const [ channel, reason ] = tail.split(" ", 2);
        conn!.client.sendMessage(ChannelPart, { channel, reason });
      } else if (cmd === "query") {
        const target = tail;
        navigate(`/!/${accountId}/${networkId}/${target}`);
      } else {
        return;
      }
    } else {
      conn!.client.sendMessage(PrivateMessage, {
        target: roomName,
        text,
      });
    }

    target.reset();
    return false;
  }

  if (isChannel(roomName)) {
    createDeferred(() => {
      // Fetch topic
      manager.getConnection(accountId, networkId)?.client.sendMessage(Topic, {
        channel: roomName,
      });
    })
  }

  return (
    <soz-room
      role="tab"
      // style={{ "content-visibility": (room() && msgs()) ? "auto" : "hidden", "--animate-duration": "400ms" }}
    >
      <header>
        <h3>{roomName}</h3>
        <Show when={room()?.topic}>
          <soz-room-topic>{room()?.topic}</soz-room-topic>
        </Show>
      </header>

      <Messages accountId={accountId} networkId={networkId} roomName={roomName} />

      <form onsubmit={sendMessage}>
        <input type="text" spellcheck={false} autocomplete="off" />
        <button type="submit">
          <BiRegularSend />
        </button>
        {appButton}
      </form>
    </soz-room>
  )
}
