import { LogLevel } from "@d-fischer/logger";
import { Message, IrcClient } from "../../vendor/ircv3";
import { AccountRecord, AccountRecordKey, NetworkRecord } from "../db";
import { BouncerMessage, ChannelJoin, ChannelKick, ChannelPart, Mode, Notice, PrivateMessage } from "../../vendor/ircv3/Message/MessageTypes/Commands";
import { RoomRecord, RoomsDB } from "../db/roomsDB";
import { Batch } from "../../vendor/ircv3/Capability/CoreCapabilities/BatchCapability/MessageTypes/Commands/Batch";
import { Reply332Topic } from "../../vendor/ircv3/Message/MessageTypes/Numerics";

import Hashes from "jshashes";

type MsgsTableMessage = PrivateMessage|Notice|ChannelJoin|ChannelPart|ChannelKick|Mode;

export default class Connection {
  readonly accountId: AccountRecordKey;
  readonly bouncerNetworkName?: NetworkRecord["name"];
  readonly bouncerNetworkId?: NetworkRecord["networkId"];

  db: RoomsDB;
  client: IrcClient;

  onBouncerNetwork?: (network: NetworkRecord) => void;
  onReceive?: (msg: Message) => void;
  onReceiveRaw?: (line: string) => void;
  onLog?: (level: LogLevel, message: string) => void;

  #chatHistoryInitialSync?: Promise<void>;
  #incomingBatches = new Map<string, [Batch, ...Message[]]>();

  #msgWriteQueue?: MsgsTableMessage[];

  constructor(account: AccountRecord, bouncerNetworkName?: NetworkRecord["name"], bouncerNetworkId?: NetworkRecord["networkId"]) {
    this.accountId = account.id;
    this.bouncerNetworkName = bouncerNetworkName;
    this.bouncerNetworkId = bouncerNetworkId;

    this.db = new RoomsDB(bouncerNetworkId ? [account.id, bouncerNetworkId] : [account.id]);

    this.client = new IrcClient({
      connection: {
        url: new URL(account.serverURL),
        reconnect: true,
      },
      manuallyAcknowledgeJoins: true,
      credentials: {
        nick: "soz",
        userName: bouncerNetworkName ? account.saslUser + "/" + bouncerNetworkName : account.saslUser,
        password: account.saslPass,
      },
      // bouncerNetworkId: bouncerNetworkId,
      logger: {
        emoji: true,
        custom: (level: LogLevel, message: string) => {
          // console.debug("[IRCv3]", this.accountId, this.bouncerNetworkName, level, message);
          this.onLog?.(level, message);
        },
      },
    });

    this.client.addCapability({
      name: "soju.im/bouncer-networks",
    });

    this.client.addCapability({
      name: "echo-message",
    });

    this.client.addCapability({
      name: "batch",
    });

    this.client.addCapability({
      name: "server-time",
    });

    this.client.addCapability({
      name: "message-tags",
    });

    this.client.addCapability({
      name: "draft/chathistory",
    });

    this.configure();

    this.client.onDisconnect((manually, reason) => {
      console.info("Connection", "disconnected", { manually, reason });
      this.#chatHistoryInitialSync = undefined;
    });

    this.client.onAnyMessage((msg: Message) => {
      if (msg.command === "FAIL") {
        console.warn("Connection", "received FAIL:", msg);
      }

      // const batchId = msg.tags.get("batch");
      // if (batchId) {
      //   const batchMsgs = this.#incomingBatches.get(batchId)!;
      //   batchMsgs.push(msg);
      // } else {
        this.onReceiveRaw?.(msg.rawLine!);
        this.onReceive?.(msg);
      // }
    });

    this.client.onRegister(() => {
      if (this.client.negotiatedCapabilities.has("draft/chathistory")) {
        this.#chatHistoryInitialSync = this.syncChatHistory();
      }
    });

    if (!bouncerNetworkName) {
      this.client.onTypedMessage(BouncerMessage, async (msg) => {
        switch (msg.subCommand) {
          // We received a new network after a LISTNETWORK.
          case "NETWORK": {
            const network = msg.parseNetworkAttribute();
            if (network) {
              this.onBouncerNetwork?.({ ...network, accountId: this.accountId });
            } else {
              console.error('Removed network?');
            }
            console.info('Received network', msg.parseNetworkAttribute());
          }
        }
      });

      this.client.onRegister(() => {
        this.client.sendMessage(BouncerMessage, {
          subCommand: "LISTNETWORKS",
        });
      });
    } else {
      // this.client.onTypedMessage(CapabilityNegotiation, cap => {
      //   if (cap.subCommand === "ACK") {
      //     if (cap.capabilities?.includes("soju.im/bouncer-networks")) {
      //       console.info("bouncerNetworks ack");
      //       this.client.sendMessage(BouncerMessage, {
      //         subCommand: "BIND",
      //         networkId: this.bouncerNetworkId,
      //       });
      //     }
      //   }
      // });
    }
  }

  connect = () => {
    this.client.connect();
  }

  disconnect = () => {
    this.client.quitAbruptly();
  }

  #configured = false
  configure() {
    if (this.#configured)
      throw new Error("Already configured");

    this.#configured = true;

    this.client.onTypedMessage(ChannelJoin, join => {
      if (!join.prefix?.nick || join.prefix?.nick !== this.client.currentNick)
        return;

      const channels = join.channel.split(",");
      for (const channel of channels) {
        this.storeChannelJoin(channel);
      }
    });

    this.client.onTypedMessage(Batch, batch => {
      const ref = batch.reference || "";
      const plusOrMinus = ref[0];
      const batchId = ref.slice(1);

      console.debug(batch.rawLine);

      if (plusOrMinus === "+") {
        this.#incomingBatches.set(batchId, [ batch ]);
      } else {
        const msgs = this.#incomingBatches.get(batchId);
        if (this.#incomingBatches.delete(batchId))
          this.handleCompleteBatch(msgs!);
      }
    })

    const queueMsgWrite = async (msg: MsgsTableMessage) => {
      const queue = this.#msgWriteQueue;
      if (queue) {
        queue.push(msg);
      } else {
        this.#msgWriteQueue = [msg];
        this.#consumeWriteQueue();
      }
    };

    this.client.onTypedMessage(PrivateMessage, async privMsg => {
      if (!privMsg.tags.has("batch")) {
        await this.#chatHistoryInitialSync;
      }
      queueMsgWrite(privMsg);
    });

    this.client.onTypedMessage(Notice, async notice => {
      if (!notice.tags.has("batch")) {
        await this.#chatHistoryInitialSync;
      }
      queueMsgWrite(notice);
    });

    this.client.onTypedMessage(ChannelJoin, async join => {
      if (!join.tags.has("batch")) {
        await this.#chatHistoryInitialSync;
      }
      if (join.prefix?.nick.toLowerCase() !== this.client.currentNick)
        queueMsgWrite(join);
    });

    this.client.onTypedMessage(ChannelPart, async part => {
      if (!part.tags.has("batch")) {
        await this.#chatHistoryInitialSync;
      }
      queueMsgWrite(part);
    });

    this.client.onTypedMessage(ChannelKick, async kick => {
      if (!kick.tags.has("batch")) {
        await this.#chatHistoryInitialSync;
      }
      queueMsgWrite(kick);
    });

    this.client.onTypedMessage(Mode, async mode => {
      if (!mode.tags.has("batch")) {
        await this.#chatHistoryInitialSync;
      }
      queueMsgWrite(mode);
    })

    this.client.onTypedMessage(Reply332Topic, async topic => {
      this.storeTopic(topic);
    });
  }

  async #consumeWriteQueue() {
    await this.db.transaction("rw", this.db.msgs, this.db.rooms, async () => {
      while (this.#msgWriteQueue?.length) {
        const msg = this.#msgWriteQueue.shift()!;
        try {
          await this.storeMsg(msg);
          if (msg instanceof PrivateMessage)
            await this.storeLastChatMarker(msg);
        } catch(ex) {
          console.error("Error writing msg:", ex);
        }
      }
    });

    this.#msgWriteQueue = undefined;
  }

  async syncChatHistory() {
    const rooms = await this.db.rooms.toArray();
    const timestamps = new Map<RoomRecord,string|undefined>();

    for (const room of rooms) {
      const lastMsg = await this.db.msgs.where("roomName").equals(room.name).last();
      timestamps.set(room, lastMsg?.time);
    }

    // Now we've got the earliest and latest timestamps, let's no longer use await:
    for (const room of rooms) {
      const lastTime = timestamps.get(room)!;
      if (lastTime) {
        console.debug(`CHATHISTORY AFTER ${room.name} timestamp=${lastTime} 999`);
        this.client.sendRaw(`CHATHISTORY AFTER ${room.name} timestamp=${lastTime} 999`);
      } else {
        console.debug(`CHATHISTORY BEFORE ${room.name} timestamp=${new Date().toISOString()} 300`);
        this.client.sendRaw(`CHATHISTORY BEFORE ${room.name} timestamp=${new Date().toISOString()} 300`);
      }
    }
  }

  async handleCompleteBatch([ batchStart, ...msgs ]: [Batch, ...Message[]]) {
    const { type } = batchStart;
    console.debug("Handle Complete Batch", type, msgs.length);
    if (type === "draft/chathistory") {
      const msgsToStore = msgs.filter(msg => msg.command === "PRIVMSG" || msg.command === "NOTICE");
      
    }
  }

  async storeTopic(topic: Reply332Topic) {
    this.db.rooms.update(topic.channel, {
      type: "channel",
      topic: topic.topic,
    })
  }

  async storeChannelJoin(name: string) {
    if (!await this.db.rooms.get(name)) {
      return this.db.rooms.add({
        name: name,
        type: "channel",

        // presence of this key is needed for the record to
        // appear in .orderBy("lastMsgTime") queries (see <RoomList />)
        lastMsgTime: "",
      }).then(roomName => {
        console.debug(`CHATHISTORY BEFORE ${roomName} timestamp=${new Date().toISOString()} 20`);
        this.client.sendRaw(`CHATHISTORY BEFORE ${roomName} timestamp=${new Date().toISOString()} 20`);
        return roomName;
      });
    }
  }

  async storeRoomForDirectMessage(senderName: string) {
    if (!await this.db.rooms.get(senderName)) {
      return this.db.rooms.add({
        name: senderName,
        type: "direct",

        // needed to make it appear in <RoomList />
        // see comment in storeChannelJoin
        lastMsgTime: "",
      })
    }
  }

  async storeMsg(msg: MsgsTableMessage) {
    const timeTag = msg.tags.get("time");
    const time = timeTag
      ? normalizeTime(timeTag)
      : normalizeTime(new Date().toISOString());

    const rawTarget = ("channel" in msg) ? msg.channel : msg.target;

    const roomName = rawTarget.toLowerCase() === this.client.currentNick.toLowerCase()
      ? msg.prefix!.nick || msg.prefixToString()
      : rawTarget;

    // console.debug(`Storing ${msg.command}`, time.toISOString(), msg.target, msg);

    if (roomName !== rawTarget)
      this.storeRoomForDirectMessage(roomName);

    const hash = new Hashes.SHA256().raw(`${msg.prefix?.nick}\n${msg.rawParamValues.join("\n")}`);

    const msgsInSameSecond = await this.db.msgs.where("[roomName+time]").between(
      [roomName, time.toISOString()],
      [roomName, new Date((+time) + 1000).toISOString()],
    ).toArray();

    if (msgsInSameSecond.length) {
      if (msgsInSameSecond.find(msg => msg.hash?.split("-")?.[1] === hash)) {
        return;
      }
    }

    // console.debug(`Sequence number`, msgsInSameSecond.length, msg.command, time.toISOString(), msg.target, msg);

    const content = ("text" in msg) ? msg.text : msg.rawParamValues.slice(1).join(" ");

    return this.db.msgs.add({
      roomName,
      time: time.toISOString(),
      msgId: msg.tags.get("msgid") ?? "0",
      hash: `${msgsInSameSecond.length.toString().padStart(4, "0")}-${hash}`,

      tags: Object.fromEntries(msg.tags),
      prefix: msg.prefixToString(),
      type: msg.command,
      content,
    });
  };

  async storeLastChatMarker(privMsg: PrivateMessage) {
    const time = normalizeTime(privMsg.tags.get("time") || new Date().toISOString()).toISOString();
    const update: Partial<RoomRecord> = {
      lastMsgPrefix: privMsg.prefixToString(),
      lastMsgTime: time,
      lastMsgId: privMsg.tags.get("msgid"),
    };
    if (privMsg.text.toLowerCase().includes(this.client.currentNick.toLowerCase())) {
      update.lastMentionPrefix = privMsg.prefixToString();
      update.lastMentionTime = time,
      update.lastMentionId = privMsg.tags.get("msgid");
    }
    return this.db.rooms.update(privMsg.target, update);
  }
};

function normalizeTime(t: string) {
  return new Date(Math.floor(Date.parse(t) / 1000) * 1000);
}
