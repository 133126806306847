import { liveQuery } from 'dexie';
import { AccountRecord, NetworkRecord, useDB } from './db';
import { For, createSignal, from } from 'solid-js';
import RoomList from './RoomList';
import { A } from '@solidjs/router';

type NetworksProps = {
  account: AccountRecord,
};

export default function Networks({ account }: NetworksProps) {
  const db = useDB();

  const networks = from(liveQuery(
    () => db.networks.where("accountId").equals(account.id).toArray()
  ));

  return (
    <nav>
      <For each={networks()}>
        {network => <Network account={account} network={network} />}
      </For>

      <button class="quiet">Add Network</button>
    </nav>
  );
}

type NetworkProps = {
  account: AccountRecord,
  network: NetworkRecord,
};

function Network({ account, network }: NetworkProps) {
  const db = useDB();

  const toggleExpand = (e: Event) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    e.preventDefault();
    db.networks.update(network, {
      "soz.expanded": !network.soz?.expanded,
    });
  }

  return (
    <details tabIndex={0} open={network.soz?.expanded ?? true}>
      <summary onClick={toggleExpand}>
        <h3 data-state={network.state}>
          <A href={`/!/${account.id}/${network.networkId}`}>
            {network.name}
          </A>
        </h3>
      </summary>

      <RoomList account={account} network={network} />
    </details>
  );
}
