/* @refresh reload */
import { render } from 'solid-js/web';

import 'solid-devtools'
import 'core-js/actual/array/at'

import { Route, Router, Routes } from "@solidjs/router";

import './index.css';
import App from './App';
import ConnectionSetup from './ConnectionSetup';

import './dev';

const root = document.getElementsByTagName("soz-app")[0] as HTMLElement;
const newRoot = document.createElement("soz-app");
newRoot.className = "animate__animated animate__fadeIn animate__fast";
root.replaceWith(newRoot);

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error(
    'Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?',
  );
}

render(() => (
  <Router>
    <Routes>
      <Route path="/connect" component={ConnectionSetup} />
      <Route path="/!/:accountId/:networkId?/:roomName?" component={App} />
      <Route path="/" component={App} data={undefined /* a loader fn */} />
    </Routes>
  </Router>
), newRoot!);
