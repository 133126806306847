import type { NetworkRecord, SozDB } from ".";
import { RoomsDB } from "./roomsDB";

// Dexie doesn't allow us to modify the primary key of a table,
// but we need to add a discriminator to the primary key to tell
// msgs in the same time interval apart during sync.
//
// At this early stage, the easiest way to make this happen for
// any alpha tester's DBs is to delete them.
//
// They'll resync right away anyhow.
export default async function tempUpgradeFix(db: SozDB) {
  const networks = await db.networks.toArray();
  const affected: NetworkRecord[] = [];

  for (const network of networks) {
    try {
      const roomsDB = new RoomsDB([network.accountId, network.networkId]);
      await roomsDB.msgs.count();
      await roomsDB.close();
    } catch(ex) {
      console.error("Error loading a RoomsDB:", ex);
      affected.push(network);
      RoomsDB.delete(RoomsDB.nameFor([network.accountId, network.networkId]));
    }
  }

  return affected;
}