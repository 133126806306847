import Dexie, { DexieOptions } from 'dexie';
import { createContext, useContext } from 'solid-js';
import { BouncerNetworkFields } from '../../vendor/ircv3/Message/MessageTypes/Commands/BouncerMessage';

export const minKey = Dexie.minKey;
export const maxKey = Dexie.maxKey;

export type AccountRecordKey = number;
export type AccountRecord = {
  id: AccountRecordKey;
  name: string;
  serverURL: string;
  saslUser?: string;
  saslPass?: string;
}

export type NetworkRecordKey = [string, string];
export type NetworkRecord = BouncerNetworkFields & {
  accountId: AccountRecordKey;
  soz?: NetworkRecordMeta;
};

type NetworkRecordMeta = {
  expanded?: boolean;
  order?: number;
}

export class SozDB extends Dexie {
  accounts!: Dexie.Table<AccountRecord, number>;
  networks!: Dexie.Table<NetworkRecord, NetworkRecordKey>;

  static defaultName = "Soz"

  constructor(name: string = SozDB.defaultName, options?: DexieOptions) {
    super(name, options);

    this.version(1).stores({
      accounts: '++id',
      networks: '[accountId+networkId], name, nickname, state, host',
    });
  }
}

export const DBContext = createContext<SozDB>();

export function useDB() {
  return useContext(DBContext)!;
}
