import { Navigate, useNavigate } from "@solidjs/router";

import { useMainConnection } from "./ConnectionProvider";

import { BiSolidGrid } from "solid-icons/bi";
import { OcPlug3 } from "solid-icons/oc";
import { Accessor, Show } from "solid-js";
import { AccountRecord } from "./db";

type AccountButtonProps = {
  account: Accessor<AccountRecord|undefined>;
};

export default function AccountButton({ account }: AccountButtonProps) {
  const hasAccount = () => !!account()?.serverURL
  const navigate = useNavigate();

  return (
    <Show when={hasAccount()} fallback={<ConnectButton />}>
      <button
        disabled={false}
        title={`Logged in as ${account()?.saslUser}. Click to manage bouncer`}
        onClick={() => navigate(`/!/${account()?.id}`)}
        class="quiet"
        style={{ "height": "3rem", width: "calc(100% - 6px)", "border-radius": "8px", "font-weight": "bold" }}>
        <BiSolidGrid fill="limegreen" size="1.5em" style={{ "vertical-align": "middle", "margin-inline-end": "6px", "translate": "0px 0px" }} />
        <span style={{
          "vertical-align": "middle",
          "pointer-events": "none",
        }}>{account()?.name}</span>
      </button>
    </Show>
  );
}

export function showConnectDialog() {
  window.open("/connect", "_blank", "popup width=300 height=420")
}

export function ConnectButton() {
  const navigate = useNavigate();

  return (
    <button
      class="quiet"
      onClick={showConnectDialog}
      style={{
        "height": "2.5rem", width: "calc(100% - 6px)", "border-radius": "8px", "font-weight": "bold"
      }}
    >
      <OcPlug3 fill="yellow" size="1.5em" style={{ "vertical-align": "middle", "margin-inline-end": "6px", color: "yellow" }} />
      <span style={{
        "vertical-align": "middle",
        "pointer-events": "none",
      }}>Connect</span>
    </button>
  );
}
