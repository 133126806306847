import { Observable, Subscription, liveQuery } from "dexie";
import { AccountRecord, AccountRecordKey, NetworkRecord, NetworkRecordKey, SozDB } from "../db";
import Connection from "./connection";
import { IrcClient, Message } from "../../vendor/ircv3";
import { ChannelJoin, PrivateMessage } from "../../vendor/ircv3/Message/MessageTypes/Commands";

type NetworkHash = string;

export function networkHash(network: NetworkRecord): NetworkHash {
  return [network.accountId, network.networkId].join(":");
}

/*
  The job of this class is to observe the DB
  for accounts and networks which should be enabled.

  For each enabled AccountRecord and NetworkRecord,
  a live IRC connection is maintained.

  The connection for an AccountRecord requests the
  draft/bouncer-networks CAP, then enumerates the
  user's networks using the `BOUNCER LISTNETWORKS`
  command.

  When a NetworkRecord is removed or disabled, the manager
  will close its associated connection.

  When an AccountRecord is removed or disabled, any
  connections for networks associated with the account are
  closed, then the top-level connection is similarly closed.
*/
export default class ConnectionManager {
  db: SozDB;

  accountConnections = new Map<AccountRecordKey, Connection>();
  networkConnections = new Map<NetworkHash, Connection>();

  accountsSubscription?: Subscription;
  networksSubscription?: Subscription;
  
  accountsObservable?: Observable;
  networksObservable?: Observable;

  activeAccounts: AccountRecord[] = [];
  activeNetworks: NetworkRecord[] = [];

  onReceive?: (connection: Connection, msg: Message) => void;
  onReceiveRaw?: (connection: Connection, rawLine: string) => void;

  constructor(db: SozDB) {
    this.db = db;
  }

  activate() {
    if (this.accountsObservable) {
      throw new Error("Already activated");
    }

    const accountsQuery = liveQuery(
      () => this.db.accounts.toArray()
    );

    const networksQuery = liveQuery(
      () => this.db.networks.toArray()
    );

    this.accountsSubscription = accountsQuery.subscribe(this.receiveAccounts);
    this.networksSubscription = networksQuery.subscribe(this.receiveNetworks);
  }

  deactivate() {
    this.accountsSubscription?.unsubscribe();
    this.networksSubscription?.unsubscribe();

    this.stopAllConnections();
  }

  realizeConnections() {
    console.info("ConnectionManager", "realizeConnections", { accounts: this.activeAccounts, networks: this.activeNetworks });

    const currentAccountIds = new Set<AccountRecordKey>(this.activeAccounts.map(acc => acc.id));
    const currentNetworkIds = new Set<NetworkHash>(this.activeNetworks.filter(net => currentAccountIds.has(net.accountId)).map(net => networkHash(net)));

    for (const accountId of this.accountConnections.keys()) {
      if (!currentAccountIds.has(accountId)) {
        this.stopAccount(accountId);
      }
    }

    for (const hash of this.networkConnections.keys()) {
      if (!currentNetworkIds.has(hash)) {
        this.stopNetwork(hash);
      }
    }

    for (const account of this.activeAccounts) {
      if (!this.accountConnections.has(account.id)) {
        this.startAccount(account);
      }
    }

    for (const network of this.activeNetworks) {
      if (!this.networkConnections.has(networkHash(network))) {
        const account = this.activeAccounts.find(acc => acc.id === network.accountId!)!;
        this.startNetwork(account, network);
      }
    }
  }

  receiveAccounts = (accounts: AccountRecord[]) => {
    console.info("ConnectionManager", "receiveAccounts", accounts);

    this.activeAccounts = accounts;
    this.realizeConnections();
  }

  receiveNetworks = (networks: NetworkRecord[]) => {
    console.info("ConnectionManager", "receiveNetworks", networks);

    this.activeNetworks = networks;
    this.realizeConnections();
  }

  startAccount(account: AccountRecord) {
    console.info("ConnectionManager", "startAccount", account.id);

    const conn = new Connection(account);
    conn.onBouncerNetwork = (network) => {
      this.db.networks.put(network);
    }
    this.accountConnections.set(account.id, conn);
    this.configureConnection(conn);
    conn.connect();
  }

  stopAccount(accountId: AccountRecordKey) {
    console.info("ConnectionManager", "stopAccount", accountId);

    const conn = this.accountConnections.get(accountId)!;
    this.accountConnections.delete(accountId);
    conn.disconnect();
  }

  startNetwork(account: AccountRecord, network: NetworkRecord) {
    console.info("ConnectionManager", "startNetwork", account.id, network.networkId);
    
    const conn = new Connection(account, network.name, network.networkId);
    this.networkConnections.set(networkHash(network), conn);
    conn.connect();
    this.configureConnection(conn);
  }

  stopNetwork(hash: NetworkHash) {
    console.info("ConnectionManager", "stopNetwork", hash);

    const conn = this.networkConnections.get(hash)!;
    this.networkConnections.delete(hash);
    conn.disconnect();
  }

  stopAllConnections() {
    for (const id of this.accountConnections.keys()) {
      this.stopAccount(id);
    }

    for (const hash of this.networkConnections.keys()) {
      this.stopNetwork(hash);
    }
  }
  
  configureConnection(conn: Connection): void {
    conn.onReceiveRaw = (line) => {
      this.onReceiveRaw?.(conn, line);
    };

    conn.onReceive = (msg) => {
      this.onReceive?.(conn, msg);
    };
  }

  getConnection(accountId: AccountRecordKey, networkId?: NetworkRecord["networkId"]): Connection|undefined {
    if (networkId === undefined) {
      return this.accountConnections.get(accountId);
    }
    return this.networkConnections.get(networkHash({ accountId, networkId } as NetworkRecord));
  }
};
