import { SetStoreFunction } from "solid-js/store";
import "./ConnectionSetup.scss";
import { ConnectionOptions } from "./ConnectionProvider";
import { Match, Switch, createSignal, from } from "solid-js";
import { DBContext, SozDB, useDB } from "./db";
import { liveQuery } from "dexie";
import { A } from "@solidjs/router";

type ConnectionSetupProps = {
};

export default function ConnectionSetup({ }: ConnectionSetupProps) {
  const db = useDB();
  const accounts = from(liveQuery(() => db.accounts.toArray()))

  return (
    <soz-connection-setup class="animate__animated animate__fadeInUp animate__fast">
      <h1 style={{"font-size": "2em"}}>Welcome to Soz</h1>
      <AccountForm />
    </soz-connection-setup>
  );
}

function AccountForm() {
  const db = useDB();

  let name: string;
  let serverURL: string;
  let saslUser: string;
  let saslPass: string;

  const onSubmit = async (e: SubmitEvent) => {
    e.preventDefault();
    (e.currentTarget as HTMLElement).setAttribute?.("disabled", "true");

    await db.accounts.put({
      id: 0,
      name,
      serverURL,
      saslUser,
      saslPass,
    });
  };

  return (
    <form onSubmit={onSubmit} style={{ width: "100%" }}>
      <aside style={{
        display: "flex",
        "gap": ".75em",
        "flex-direction": "column",
        "margin-bottom": "1em",
        "padding": "0px",
        "min-width": "min-content",
        // border: "1px solid magenta",
        "flex": "0 1 auto",
        // "border": "4px double dodgerblue",
        "border-radius": ".2em",
        // background: "rgb(0 0 0 / .1)",
        color: "#ddd",
      }}>
        <soz-message-text>
          Login to a <A href="https://soju.im">soju.im</A>
        </soz-message-text>

        <soz-message-text>
          Catch up on any device
        </soz-message-text>

        <soz-message-text>
          Best suited for users of <A href="https://git.sr.ht/~emersion/gamja">Gamja</A> or <a href="https://git.sr.ht/~taiite/senpai">Senpai</a> atm
        </soz-message-text>

        {/* <button type="button" onClick={() => setShowWelcome(false)}>Dismiss</button> */}
      </aside>

      <div>
        <label>
          <span>
            Name
          </span>

          <input name="name" onInput={(e) => name = e.currentTarget.value } />
        </label>

        <label>
          <span>
            Server URL
          </span>

          <input name="serverURL" onInput={(e) => serverURL = e.currentTarget.value} placeholder="wss://soju.example/" />
        </label>

        <label>
          <span>
            Username
          </span>

          <input name="saslUser" onInput={(e) => saslUser = e.currentTarget.value } />
        </label>

        <label>
          <span>
            Password
          </span>

          <input type="password" name="saslPass" onInput={(e) => saslPass = e.currentTarget.value } />
        </label>

        <br />

        <button type="submit">
          Login
        </button>
      </div>
    </form>
  );
}

function CloseButton() {
  return (
    <button onClick={() => window.close()}>Close</button>
  )
}
