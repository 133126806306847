import { createContext, createEffect, createSignal, useContext } from "solid-js";
import { AccountRecordKey, NetworkRecord } from "../db";
import Connection from "./connection";
import ConnectionManager from "./manager";

export const ConnectionManagerContext = createContext<ConnectionManager>();

export function useConnectionManager() {
  return useContext(ConnectionManagerContext)
}

export function useConnection(accountId: AccountRecordKey, networkId?: NetworkRecord["networkId"]) {
  const manager = useConnectionManager()!;
  return manager?.getConnection(accountId, networkId);
}
