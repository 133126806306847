import { createSignal, type Component, createEffect, For, onCleanup, from, Show, JSXElement, Signal } from 'solid-js';
import { A, useNavigate, useParams } from "@solidjs/router";

import { makePersisted } from '@solid-primitives/storage';
import { liveQuery } from 'dexie';

import { SozDB, DBContext, AccountRecordKey, NetworkRecord } from './db';
import tempUpgradeFix from './db/tempUpgradeFix';

import { ConnectionManagerContext } from './connection/context';
import type Connection from './connection/connection';
import ConnectionManager from './connection/manager';

import 'animate.css';

import './App.scss';
import './Room.scss';

import { BiRegularFoodMenu, BiRegularMenu } from 'solid-icons/bi';
import { OcGlobe3 } from 'solid-icons/oc';

import AccountButton from './AccountButton';
import Networks from './Networks';
import ConnectionRoom from './ConnectionRoom';
import Room from './Room';
import RoomList from './RoomList';
import ConnectionSetup from './ConnectionSetup';

// TODO: Refactor into several components, hooks
const App: Component = () => {
  // const routeData = useRouteData<any>()();
  // console.info('<App>', 'routeData', routeData);

  const db = new SozDB();
  const params = useParams<{ accountId?: string, networkId?: string, roomName?: string }>();

  const [barHidden, setBarHidden] = makePersisted(createSignal(false), { storage: sessionStorage });
  let bar: HTMLElement | undefined = undefined;

  (globalThis as any).db = db;
  tempUpgradeFix(db).then(affected => {
    if (affected.length)
      location.reload();
  });

  let toggleBar = () => {
    setBarHidden(!barHidden());
    if (!barHidden()) {
      (bar?.querySelector("button:last-child") as HTMLElement)?.focus();
    }
  }
  // if ("startViewTransition" in document) {
  //   toggleBar = (f => () => document.startViewTransition(f))(toggleBar)
  // }

  // Hide the bar after navigating when viewport is narrow
  // and the bar fills the screen
  createEffect(() => {
    [params.accountId, params.networkId, params.roomName];

    if (!params.accountId && !params.networkId) {
      setBarHidden(false);
    } else if (getComputedStyle(bar!).position === "absolute") {
      setBarHidden(true);
    }
  })

  const keyListener = (e: KeyboardEvent) => {
    if (e.key == 'Escape') {
      toggleBar();
      if (barHidden()) {
        bar?.querySelector<HTMLElement>(":focus")?.blur();
      }
    }
  }

  document.addEventListener("keydown", keyListener);
  onCleanup(() => {
    document.removeEventListener("keydown", keyListener);
  });

  let clickSound: HTMLAudioElement;
  let clickSoundController: AbortController;

  const playClickSound = (e: PointerEvent) => {
    clickSound = new Audio("/single-click.ogg");
    clickSoundController = new AbortController();

    setTimeout(() => {
      if (clickSoundController.signal.aborted) {
        return;
      }

      clickSound.volume = 0.7 + (Math.random() - 0.5) / 8;
      clickSound.play();
    }, e.pointerType === "mouse" ? 0 : 33);
  }

  const cancelClickSound = () => {
    clickSound?.pause();
    clickSoundController?.abort();
  }

  // document.body.addEventListener("pointerdown", playClickSound);
  // onCleanup(() => document.body.removeEventListener("pointerdown", playClickSound));

  // document.body.addEventListener("touchmove", cancelClickSound);
  // onCleanup(() => document.body.removeEventListener("touchmove", cancelClickSound));

  const connectionManager = new ConnectionManager(db);
  (globalThis as any).manager = connectionManager;

  const rawConnectionLogs = new WeakMap<Connection, Signal<string[]>>();

  function rawLogSignal(conn: Connection) {
    let signal = rawConnectionLogs.get(conn);
    if (!signal) {
      signal = createSignal([]);
      rawConnectionLogs.set(conn, signal);
    }
    return signal;
  }

  connectionManager.onReceiveRaw = (conn, line) => {
    const [ log, setLog ] = rawLogSignal(conn);
    setLog([ ...log(), line ]);
  }

  connectionManager.activate();
  onCleanup(() => connectionManager.deactivate());

  const navigate = useNavigate();

  const accounts = from(liveQuery(() => db.accounts.toArray()));

  // db.accounts.toArray(accounts => {
  //   if (!accounts.length) {
  //     navigate('/connect');
  //   }
  // });

  const $sidebarToggle = (
    <button type="button" title="Toggle Sidebar (Esc)" aria-label="Toggle Sidebar" aria-keyshortcuts="Escape" aria-checked={!barHidden()} onClick={() => toggleBar()} style={{
      // position: "fixed",
      // top: ".3rem",
      // right: ".25rem",
      "min-height": "1rem",
      // display: "flex",
      // background: barHidden() ? "" : "var(--main-bg)",
      // "font-size": "1.5em",
      // "border-color": "lightgray",
      // padding: ".25em 1.25em",
      // "box-shadow": "3px 3px 9px var(--app-bg)",
    }}><BiRegularMenu fill={barHidden() ? "lightgray" : "yellow"} /></button>
  );

  const [ room, setRoom ] = createSignal<JSXElement>();
  createEffect(() => {
    const { accountId, networkId, roomName } = params;
    if (accountId) {
      setRoom(makeRoom(+accountId, networkId, roomName));
    } else {
      setRoom();
    }
  });

  function makeRoom(accountId: AccountRecordKey, networkId?: NetworkRecord["networkId"], roomName?: string) {
    if (roomName) {
      return <ConnectionManagerContext.Provider value={connectionManager}>
        <Room accountId={accountId} networkId={networkId} roomName={roomName} appButton={$sidebarToggle} />
      </ConnectionManagerContext.Provider>;
    }

    const conn = connectionManager.getConnection(+accountId, networkId)!;
    const [ rawLog ] = rawLogSignal(conn);

    return <ConnectionManagerContext.Provider value={connectionManager}>
      <ConnectionRoom accountId={+accountId} networkId={networkId} log={rawLog} />
    </ConnectionManagerContext.Provider>;
  }

  return (
    <DBContext.Provider value={db}>
      <ConnectionManagerContext.Provider value={connectionManager}>
        <Show when={!accounts() || accounts()!.length} fallback={<ConnectionSetup />}>
          <soz-bar hidden={barHidden() || !accounts()} ref={bar} onFocusIn={() => setBarHidden(false)}>
            <For each={accounts()} fallback={<nav><h3 style={{ "padding": ".5em"}}>Setup</h3></nav>}>
              {account => <>
                {/* <RoomList account={account} /> */}
                <Networks account={account} />
              </>}
            </For>

            <section>
              <button class="show-only-if-bar-covers-screen"
                onClick={() => setBarHidden(!barHidden())}
                hidden={!params.accountId}
                style={{
                  background: "var(--main-bg)",
                  opacity: 0.8,
                  padding: ".75em",
                  "justify-content": "center",
                  "align-items": "center",
                  gap: ".25em",
                }}>
                {/* <BiRegularFoodMenu fill="dodgerblue" style={{ "font-size": "1.5em" }} /> */}
                Close
              </button>

              <button
                disabled={!accounts()?.length}
                title="Find networks and channels to join - not yet implemented"
                class="quiet"
                style={{ "height": "2.5rem", width: "calc(100% - 6px)", "border-radius": "8px", "font-weight": "bold" }}>
                <OcGlobe3 fill="yellow" size="1.5em" style={{ "vertical-align": "middle", "margin-inline-end": "6px", "translate": "0px 0px" }} />
                <span style={{
                  "vertical-align": "middle",
                  "pointer-events": "none",
                }}>Browse</span>
              </button>

              <AccountButton account={() => accounts()?.[0]} />

              <A href="https://git.sr.ht/~mooff/soz" target="_blank" style={{ "display": "inline-block", "width": "calc(100% - 6px)", "text-align": "center", "font-size": ".925em", "margin-block": ".5em", "color": "#aaa" }}>Source code</A>
            </section>
          </soz-bar>

          <soz-main>
            <Show when={accounts()}>
              <Show when={params.accountId} fallback={<h4 style={{ "place-self": "center" }}></h4>}>
                {room()}
              </Show>
            </Show>
            {/* <Room name="#music" /> */}
          </soz-main>
        </Show>
      </ConnectionManagerContext.Provider>
    </DBContext.Provider>
  );
};

export default App;
