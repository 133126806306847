import { Message, type MessageInternalConfig, type MessageInternalContents } from '../../Message';

export interface BouncerNetworkFields {
	networkId?: string;
	name?: string;
	state?: "connected" | "connecting" | "disconnected";
	host?: string;
	port?: string;
	tls?: string;
	nickname?: string;
	username?: string;
	realname?: string;
	pass?: string;
}

export class BouncerNetwork {
	constructor(attrs: Partial<BouncerNetworkFields>) {
		Object.assign(this, attrs);
	}
	toAttributeString(): string {
		const fields: (keyof BouncerNetworkFields)[] = ["name", "host", "port", "tls", "nickname", "username", "realname", "pass"];

		// TODO: Escaping has to be performed here.
		return fields.filter(key => (this as any)[key] != null).map(key => `${key}=${(this as any)[key]}`).join(';')
	}

	get isSecure(): boolean {
		return this.tls == "1";
	}
}

export type BouncerMessageSubCommand = "BIND" | "LISTNETWORKS" | "ADDNETWORK" | "NETWORK" | "CHANGENETWORK" | "DELNETWORK";
interface BouncerMessageFields {
	subCommand: BouncerMessageSubCommand,
	networkId?: string;
	networkAttribute?: string;
}

export interface BouncerMessage extends BouncerMessageFields {}
export class BouncerMessage extends Message<BouncerMessageFields> {
	static readonly COMMAND = 'BOUNCER';
	constructor(command: string, contents?: MessageInternalContents, config?: MessageInternalConfig) {
		super(command, contents, config, {
			subCommand: {
				match: /^(?:LISTNETWORKS|ADDNETWORK|NETWORK|CHANGENETWORK|DELNETWORK|BIND)$/i
			},
			networkId: {
				optional: true
			},
			networkAttribute: {
				optional: true
			},
		});
	}

	parseNetworkAttribute(): BouncerNetwork | null {
		if (!this.networkAttribute || !this.networkId) {
			return null;
		}

		// TODO: here, we don't do proper parsing.
		// Bring something that takes the types and does:
		// validation & parsing.
		return Object.assign({}, {
			networkId: this.networkId,
		}, Object.fromEntries(
			this.networkAttribute.split(';').map(attr => attr.split('='))
		));
	}
}
