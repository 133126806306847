import { For, from } from "solid-js";
import { AccountRecord, NetworkRecord } from "./db";
import { liveQuery } from "dexie";
import { RoomRecord, RoomsDB } from "./db/roomsDB";
import { A, useParams } from "@solidjs/router";

type RoomListProps = {
  account: AccountRecord;
  network?: NetworkRecord;
};

export default function RoomList({ account, network }: RoomListProps) {
  const roomsDB = new RoomsDB(network ? [account.id, network.networkId] : [account.id]);

  const rooms = from(liveQuery(
    () => roomsDB.rooms.orderBy("lastMsgTime").toArray()
  ));

  const params = useParams();
  function isCurrent(room: RoomRecord) {
    const currentRoomName = decodeURIComponent(params.roomName);
    return (+params.accountId === account.id) && (params.networkId === network?.networkId) && (currentRoomName === room.name);
  }

  function hint(room: RoomRecord) {
    if (!room.lastMsgTime)
      return room.name;

    let hint = "Last msg ";
    if (room.lastMsgPrefix)
      hint += `from ${room.lastMsgPrefix.split("@", -2)[0].split("!")[0]} `;
    hint += `@ ${new Date(room.lastMsgTime).toLocaleString()}`;
    return hint;
  }

  return (
    <ul role="tablist" aria-label="Chat List" tabIndex={0}>
      <For each={rooms()?.reverse()}>
        {room => (
          <li aria-current={isCurrent(room)} classList={{
            unread: !!(room.lastMsgTime && (!room.lastReadTime || (new Date(room.lastReadTime) < new Date(room.lastMsgTime)))),
            hasMention: !!(room.lastMentionTime && (!room.lastReadTime || (new Date(room.lastReadTime) < new Date(room.lastMentionTime)))),
          }}>
            <A
              tabindex={0}
              role="tab"
              href={`/!/${account.id}/${network?.networkId || "-"}/${encodeURIComponent(room.name)}`}
              title={hint(room)}
            >
              <>
                {room.name}
              </>
            </A>
          </li>
        )}
      </For>
    </ul>
  );
}
